<template>
  <div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="activity" v-show="!isLoading(loadingName)">
      <b-row class="hide-here">
        <b-col cols="6">
          <b>{{ structureName }}</b>
        </b-col>
        <b-col l cols="6" class="text-right">
          {{ currentTime }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="members-col" cols="9">
          <b>{{ activity.name }} - {{ activity.schoolYear.name }}</b>
        </b-col>
        <b-col cols="3">
          <table v-if="showFields && showLegend" class="table text-left small-table">
            <tr class="no-print"><th>Légende</th></tr>
            <tr v-for="field of fields" :key="field.id">
              <td v-if="field.fullText !== field.text">
                {{ field.text}}: {{ field.fullText }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <div v-if="activityInscriptions.length === 0">Aucune inscription pour l'instant</div>
      <div v-for="elt of scopes" :key="elt.id">
        <div v-if="elt.inscriptions.length">
          <div class="grouper-header">{{ elt.title }} ({{ elt.inscriptions.length }})</div>
          <table class="table table-grid text-center">
            <tr v-if="seances.length">
              <th class="text-left">Date</th>
              <th v-if="showAge">&nbsp;</th>
              <th v-if="showBirth">&nbsp;</th>
              <th v-if="showFields">&nbsp;</th>
              <th v-if="showPhone">&nbsp;</th>
              <th v-if="showEmail">&nbsp;</th>
              <td
                v-for="seance of seances"
                :key="seance"
                :style="seanceDateStyle"
              >
                <div
                  :class="vertCol ? 'rotate-me small-dt' : 'small-text'"
                  :style="vertWidth"
                >
                  {{ getSeanceDate(seance) }}
                </div>
              </td>
            </tr>
            <tr>
              <th class="text-left">Nom</th>
              <th v-if="showAge" style="min-width: 100px;">Age</th>
              <th v-if="showBirth" style="min-width: 100px;">Né(e) le</th>
              <th v-if="showFields" style="min-width: 100px;">Infos</th>
              <th v-if="showPhone">Téléphone</th>
              <th v-if="showEmail">Email</th>
              <th v-for="seance of seances" :key="seance" :style="seanceStyle">{{ seance }}</th>
            </tr>
            <tr v-for="inscription of elt.inscriptions" :key="inscription.id" class="inscriptions-list">
              <td class="text-left">
                <router-link :to="getEntityLink(inscription)">
                  {{ inscription.individual.firstAndLastName() }}
                </router-link>
                <span
                  v-if="inscription.trying"
                  class="badge"
                  :class="inscription.isTryingFinished() ? 'badge-danger' : 'badge-dark'"
                >
                  <i class="fa fa-warning" v-if="inscription.isTryingFinished()"></i>
                  A l'essai
                  <span v-if="inscription.tryingDate">
                    jusqu'au {{ inscription.tryingDate | dateToString }}
                  </span>
                </span>
                <span v-if="inscription.waiting" class="badge badge-dark">En attente</span>
                <span v-if="inscription.hasLeftOn" class="badge badge-danger">
                  a quitté le {{ inscription.hasLeftOn | dateToString }}
                </span>
              </td>
              <td v-if="showAge" class="small-text-col">{{ inscription.individual.getAge() }}</td>
              <td v-if="showBirth" class="small-text-col">{{ inscription.individual.birthDate | dateToString }}</td>
              <td v-if="showFields" class="small-text-col">
                <div v-for="field of fields" :key="field.id">
                  <a
                    href
                    @click.prevent="onEditField(inscription, field)"
                    v-if="isFieldVisible(inscription, field)"
                  >
                    {{ field.text}}: {{ inscription.getFieldText(field) | defaultValue('-') }}
                  </a>
                </div>
              </td>
              <td v-if="showPhone" class="small-text-col">
                <phone-link :phone="inscription.getCellPhoneOrProPhone()"></phone-link>
              </td>
              <td v-if="showEmail" class="small-text-col">
                <email-link :email="inscription.getEmail()" default-value="-"></email-link>
              </td>
              <td v-for="seance of seances" :key="seance" :style="seanceStyle">
                <span v-if="activity.allowPresence && getDateObj(seance)">
                  <agenda-presence-indicator
                    model="activity"
                    :id="activity.id"
                    :individual="inscription.individual"
                    :date="getDateObj(seance)"
                    :map="presenceMap"
                    @click="onAgendaPresenceClicked"
                  >
                  </agenda-presence-indicator>
                </span>
                <span v-else>&nbsp;</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <individual-field-modal
      :individual="selectedIndividual"
      :field="selectedField"
      :read-only="!(selectedField && selectedField.canEdit)"
      :id="activityId"
      @updated="onIndividualFieldUpdated()"
    >
    </individual-field-modal>
    <set-agenda-presence-modal
      v-if="activity"
      :modal-id="'set-agenda-presence-modal' + activity.id"
      :item="agendaPresence"
      @saved="onPresenceSaved"
    ></set-agenda-presence-modal>
  </div>
</template>

<script>
import EmailLink from '@/components/Controls/Links/EmailLink'
import PhoneLink from '@/components/Controls/Links/PhoneLink'
import LoadingGif from '@/components/Controls/LoadingGif'
import IndividualFieldModal from '@/components/Fields/IndividualFieldModal'
import AgendaPresenceIndicator from '@/components/Agenda/AgendaPresenceIndicator'
import SetAgendaPresenceModal from '@/components/Agenda/SetAgendaPresenceModal.vue'
import { BackendApi, getHostName } from '@/utils/http'
import { makeActivityDate, makeAgendaDatePresence } from '@/types/agenda'
import { makeActivity, makeActivityInscription, makeActivityListField } from '@/types/activities'
import store from '@/store'
import moment from 'moment/moment'
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { compareDates } from '@/utils/sorting'
import { dateToString } from '@/filters/texts'

export default {
  name: 'TabActivitiesInscriptions',
  mixins: [BackendMixin],
  props: {
    showAge: Boolean,
    showBirth: Boolean,
    showFields: Boolean,
    showLegend: Boolean,
    showPhone: Boolean,
    showEmail: Boolean,
    showTryingLimits: Boolean,
    seancesCount: {
      type: [String, Number],
      default: Number,
    },
    activityId: [String, Number],
    fromDate: String,
  },
  components: {
    SetAgendaPresenceModal,
    AgendaPresenceIndicator,
    EmailLink,
    PhoneLink,
    LoadingGif,
    IndividualFieldModal,
  },
  data() {
    return {
      activity: null,
      activityInscriptions: [],
      currentTimeFormat: 'llll',
      currentTime: '',
      loadingName: 'activity-seances',
      fields: [],
      selectedIndividual: null,
      selectedField: null,
      activityDates: [],
      presenceMap: new Map(),
      agendaPresence: null,
    }
  },
  watch: {
    activity: function() {},
    activityInscriptions: function() {},
    showTryingLimits: function() {},
    fromDate: function() {},
  },
  computed: {
    vertCol() {
      return (this.seancesCount > 12) && (this.activityDates.length > 12)
    },
    vertWidth() {
      if (this.vertCol) {
        let value = 70
        if (this.seancesCount > 20) {
          value = 40
        }
        if (this.seancesCount > 30) {
          value = 20
        }
        return {
          width: '' + value + 'px',
        }
      }
      return {}
    },
    structureName() {
      return store.getters.structureName
    },
    isYouthHome() {
      return +this.youthHome
    },
    seanceStyle() {
      let style = 'border: solid 1px #ccc; text-align: center;'
      if (this.seancesCount) {
        style += 'width: ' + (80 / this.seancesCount) + '%;'
      } else {
        style += 'width: 70%;'
      }
      return style
    },
    seanceDateStyle() {
      let style = this.seanceStyle
      if (this.vertCol) {
        if (this.seancesCount > 20) {
          style += 'height: 70px;'
        } else {
          style += 'height: 90px;'
        }
      }
      return style
    },
    backendName() {
      return getHostName(store.getters.backendName)
    },
    inscriptions() {
      return this.activityInscriptions.filter(
        elt => !elt.waiting && !elt.trying
      )
    },
    waitingInscriptions() {
      return this.activityInscriptions.filter(
        elt => elt.waiting && !elt.trying
      )
    },
    tryingInscriptions() {
      let inscriptions = this.activityInscriptions.filter(
        elt => !elt.waiting && elt.trying
      )
      if (!this.showTryingLimits) {
        inscriptions = inscriptions.filter(
          elt => !elt.isTryingFinished()
        )
      }
      return inscriptions
    },
    scopes() {
      return [
        { id: 1, title: 'Inscriptions définitives', inscriptions: this.inscriptions, },
        { id: 2, title: 'Inscriptions à l\'essai', inscriptions: this.tryingInscriptions, },
        { id: 3, title: 'Inscriptions en attente', inscriptions: this.waitingInscriptions, },
        { id: 4, title: 'Inscriptions en attente et à l\'essai', inscriptions: this.waitingButTryingInscriptions, }
      ]
    },
    waitingButTryingInscriptions() {
      let inscriptions = this.activityInscriptions.filter(
        elt => elt.waiting && elt.trying
      )
      if (!this.showTryingLimits) {
        inscriptions = inscriptions.filter(
          elt => !elt.isTryingFinished()
        )
      }
      return inscriptions
    },
    seances() {
      return Array.from({ length: this.seancesCount, }, (value, index) => index + 1)
    },
    editMode() {
      return (store.getters.editMode !== '')
    },
    selectorDisabled() {
      return (store.getters.editMode !== '')
    },
  },
  created() {
    this.currentTime = moment().format(this.currentTimeFormat)
    this.loadData()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.startLoading(this.loadingName)
      await this.loadActivity()
      await this.loadInscriptions()
      await this.loadActivityDates()
      await this.loadPresences()
      this.endLoading(this.loadingName)
    },
    async loadActivityDates() {
      try {
        let url = '/agenda/api/activity-dates/?activity=' + this.activityId
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.activityDates = resp.data.map(elt => makeActivityDate(elt))
        this.$emit('dates-loaded', { dates: this.activityDates, })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getEntityLink(inscription) {
      return {
        name: 'families-detail',
        params: { entityId: '' + inscription.entity.id, },
        query: { individual: inscription.individual.id, },
      }
    },
    onEditField(inscription, field) {
      this.selectedField = field
      this.selectedIndividual = inscription.individual
      this.$bvModal.show('bv-individual-field-modal' + this.activityId)
    },
    onIndividualFieldUpdated() {
      this.loadInscriptions()
    },
    isFieldVisible(inscription, field) {
      if (inscription.isFieldValueVisible(field)) {
        if (field.canEdit) {
          return true
        } else {
          return inscription.getFieldText(field)
        }
      }
      return false
    },
    async loadActivity() {
      try {
        let url = '/api/activities/activities/' + this.activityId + '/'
        if (this.isYouthHome) {
          url = '/api/youth/activities/' + this.activityId + '/'
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.activity = makeActivity(resp.data)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadInscriptions() {
      try {
        let url = '/api/activities/activities/' + this.activityId + '/inscriptions/'
        if (this.isYouthHome) {
          url = '/api/youth/activities/' + this.activityId + '/inscriptions/'
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.activityInscriptions = resp.data.inscriptions.map(makeActivityInscription).filter(
          elt => !elt.cancelled
        )
        this.fields = resp.data.fields.map(makeActivityListField)
        this.$emit('inscriptions-loaded', { inscriptions: this.activityInscriptions, })
        this.$emit('fields-loaded', { fields: this.fields, })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadPresences() {
      if (this.activity.allowPresence) {
        try {
          let url = '/agenda/api/agenda-date-presences/activity/' + this.activityId + '/'
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          const presences = resp.data.map(makeAgendaDatePresence)
          const presenceMap = new Map()
          for (const presence of presences) {
            presenceMap.set(presence.getKey(), presence)
          }
          this.presenceMap = presenceMap
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    getDateObj(seance) {
      if (this.activityDates.length) {
        let dates = this.activityDates
        if (this.fromDate) {
          dates = this.activityDates.filter(
            elt => {
              let value = compareDates(elt.startDateTime, moment(this.fromDate).toDate())
              return value >= 0
            }
          )
        }
        let index = seance - 1
        if (index < dates.length) {
          return dates[index]
        }
      }
      return null
    },
    getSeanceDate(seance) {
      const dateObj = this.getDateObj(seance)
      if (dateObj) {
        return dateToString(dateObj.startDateTime, 'DD/MM/YYYY')
      }
      return ''
    },
    onAgendaPresenceClicked(event) {
      this.agendaPresence = event
      const that = this
      this.$nextTick(
        () => {
          that.$bvModal.show('set-agenda-presence-modal' + that.activity.id)
        }
      )
    },
    onPresenceSaved(presence) {
      this.presenceMap.set(presence.getKey(), presence)
      this.presenceMap = new Map(this.presenceMap)
    },
  },
}
</script>

<style scoped>
.rotate-me {
  transform-origin: top center;
  transform: rotate(90deg) translate(50%);
  margin-right: -60px;
  padding-right: -60px;
}
.small-dt {
  font-size: 10px;
}
</style>
